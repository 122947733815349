const {Carousel} = antd
const {useSize} = ahooks
const {useState, useEffect} = React
const avatars = [
    "https://cdn-static.xiaoduoai.com/apphub/bitable/e9b20f98367b9f579245d3d71046a741.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/640bdd971ea8a4bda7fcef4b216e054f.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/8a42608d63b8018c73c4d7d1c9569f05.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/dd201df1ef12a511ea80e77412d3fb65.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/d66b3ac11d22ac8edb0bc466e2ac9513.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/e87a40c6f3a6c23e5688541a440aba68.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/dd8ea3ef2dfbfe10d784fb4420dcc3aa.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/2041cc5fe6f26b8b97a06f3a472c29af.png",
    "https://cdn-static.xiaoduoai.com/apphub/bitable/367d3e293cd9e7b3fee94543cb7ef6fb.png",
]

export function splitIntoGroups(array, numGroups = 3) {
    const result = Array.from({length: numGroups}, () => []);
    const avatarLength = avatars.length
    let currentAvatar = 0
    array.forEach((item, index) => {
        if (currentAvatar >= avatarLength) {
            currentAvatar = 0
        }
        item.avatar = avatars[currentAvatar]
        currentAvatar++
        result[index % numGroups].push(item);
    });

    return result;
}


const orders = [
    {
        "username": "XDUmQpt0rcnz的团队",
        "product": "知立方",
    },
    {
        "username": "广州市飞石电子科技有限公司",
        "product": "商品知识库",
    },
    {
        "username": "深圳市晓谷科技有限公司",
        "product": "AI训练场",
    },
    {
        "username": "成都市笑脸科技有限公司",
        "product": "AI训练场",
    },
    {
        "username": "江苏先声药业有限公司",
        "product": "VOC.AI",
    },
    {
        "username": "青岛大海边电子商务有限公司",
        "product": "VOC.AI",
    },
    {
        "username": "佛山市顺德区顺之然电器实业有限公司",
        "product": "VOC.AI",
    },
    {
        "username": "北京旸斯嘉商贸有限公司",
        "product": "VOC.AI",
    },
    {
        "username": "上海铸塔信息技术有限公司",
        "product": "知立方",
    },
    {
        "username": "江西秋田满满食品科技有限公司",
        "product": "知立方",
    },
    {
        "username": "XD2ufzFIEAip的团队",
        "product": "速晓工作台",
    },
    {
        "username": "XDdTDxKK08qC的团队",
        "product": "速晓工作台",
    },
    {
        "username": "武汉润蜂电子商务有限公司",
        "product": "AI训练场",
    },
    {
        "username": "XDCfC7qjBrqR的团队",
        "product": "AI训练场",
    },
    {
        "username": "南京顺隆电器商贸有限公司",
        "product": "买家非满评价分析-数码家电-[VOC AI]",
    },
    {
        "username": "杭州启元科技有限公司",
        "product": "赠品促销分析-家具-[VOC AI]",
    },
    {
        "username": "浙江左狐信息科技有限公司",
        "product": "电商退货退款分析-数码家电-[VOC AI]",
    },
    {
        "username": "漯河市卫龙商贸有限公司",
        "product": "电商退货退款分析-食品-[VOC AI]",
    },
    {
        "username": "苏州雷允上医药科技有限公司",
        "product": "电商询单转化分析-医药保健-[VOC AI]",
    },
    {
        "username": "苏州维思拓网络科技有限公司",
        "product": "AI自动邀请顾客好评",
    }
]

const defaultOrderGroups = splitIntoGroups(orders, 3)
const MultiVerticalCarousel = () => {
    const size = useSize(() => document.body)
    const [orderGroups, setOrderGroups] = useState(defaultOrderGroups)
    const currentWidth = size?.width || 1440
    useEffect(() => {
        if (currentWidth > 992 && currentWidth < 1200) {
            setOrderGroups(splitIntoGroups(orders, 2))
        } else if (currentWidth < 992) {
            console.log("hello")
            setOrderGroups(splitIntoGroups(orders, 1))
        } else {
            setOrderGroups(splitIntoGroups(orders, 3))
        }
    }, [size]);
    return (
        <div
            className="hot_buy_main"
        >
            <div className="hot_buy_left_image">
                <img src="https://cdn-static.xiaoduoai.com/apphub/bitable/8034c6cf53e2af475100eb94260497cf.png" alt=""/>
            </div>
            <div className="hot_buy_right">
                {orderGroups.map((orderList, index) => (
                    <Carousel key={index} autoplay vertical dots={false} className="hot_buy_right_carousel">
                        {orderList.map((order, idx) => (
                            <div
                                key={idx}
                                className="hot_buy_right_carousel_item"
                            >
                                <div className="hot_buy_right_carousel_item_inner">
                                    <img
                                        className="hot_buy_right_carousel_item_inner_avatar"
                                        src={order.avatar}
                                        alt=""
                                    />
                                    <span className="hot_buy_right_carousel_item_inner_right" title={order.product}>
                                        <span
                                            className="">{order.username.slice(0, 2)}**{order.username.slice(-4)}</span>
                                        <span className="">购买了</span>
                                        <span className="hot_buy_right_carousel_item_inner_right_item"
                                              style={{color: "#FF620D"}}>{order.product}</span>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ))}
            </div>
        </div>

    );
};

export default MultiVerticalCarousel;
